<template>
  <v-data-table
    :headers="headers"
    hide-default-footer
    :loading="loading"
    :items="items"
    :items-per-page="-1"
    sort-by="number"
    sort-desc=""
    :item-class="
      (item) =>
        item.status.code == 'rejected' ? 'red lighten-4 clickable' : 'clickable'
    "
    @click:row="
      (item) =>
        $router.push({
          name: 'InvoiceDetails',
          params: { id: item.id, page: page },
        })
    "
  >
    <template v-slot:item.date="{ item }">
      <DateValue :value="item.date" />
    </template>
    <template v-slot:item.status="{ item }">
      <v-tooltip top v-if="item.status.code == 'rejected'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-alert</v-icon>
        </template>
        <span>{{ item.status.description }} </span>
      </v-tooltip>
    </template>
    <template v-slot:item.payee="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            v-if="item.payee.code == 'schoolFund'"
            >mdi-printer</v-icon
          >
          <v-icon
            v-bind="attrs"
            v-on="on"
            v-else-if="item.payee.code == 'original'"
            >mdi-credit-card</v-icon
          >
          <v-icon
            v-bind="attrs"
            v-on="on"
            v-else-if="item.payee.code == 'employee'"
            >mdi-account</v-icon
          >
        </template>
        <span>{{ item.payee.description }} </span>
      </v-tooltip>
    </template>
    <template v-slot:item.amount="{ item }">
      <CurrencyValue :value="item.amount" />
    </template>
    <template v-slot:footer
      ><TableFooter
        :items="items"
        label="Rechnungen"
        labelSingular="Rechnung"
      ></TableFooter
    ></template>
  </v-data-table>
</template>
<script>
import CurrencyValue from "@/components/CurrencyValue.vue";
import DateValue from "common/components/DateValue.vue";
import TableFooter from "common/components/TableFooter.vue";

export default {
  components: { CurrencyValue, DateValue, TableFooter },
  props: {
    page: { type: String, default: "InvoicePending" },
    items: { type: Array },
  },
  data() {
    return {
      headers: [
        { text: "", value: "status" },
        { text: "Nummer", value: "number" },
        { text: "Datum", value: "date" },
        { text: "Zahlung", value: "payee" },
        { text: "Anlass", value: "event" },
        { text: "Beschreibung", value: "description" },
        { text: "Betrag", value: "amount", align: "right" },
      ],
      loading: false,
    };
  },
};
</script>
